// Here you can add other styles
$cui-accordion-border-color: red;

.register .valid {
    background-color: lightgreen;
}

.register .showerr {
    background-color: #faadad;
    border-radius: 6px;
    border: 1px solid red;
    padding: 2px;
}

.register .hideerr {
    display: none;
}

.project_missing_info {
    font-weight: bold;
    color:orangered;
}

.strikethrough { 
    text-decoration-line: line-through;
}

.borderless {
    border-style: hidden;
    border-width: 0;
}
 
.sequencetd {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: 'Courier New', Courier, monospace;  
}

.sequence {
    font-family: 'Courier New', Courier, monospace;    
}

// [data-column-id="genes_id_head"].rdt_TableCol {
    .rdt_TableCol {
        color:orange;
        font-weight: bold;
        font-size: 140%;    
    }
    
    // komórki w reactd-data-table-component
    .rdtc_cell_mainwrapper {
        display: table;
    }
    
    .rdtc_rowitemwrapper {
        display: table-row;
        padding-left: 5pt;
    }
    
    .rdtc_item_left {
        display: table-cell;
        
        white-space: nowrap;
    }
    
    .rdtc_item_right {
        display: table-cell;
        padding-left: 5pt;
        color: orange;
    }
    
    .rdtc_subtitle {
        color: #BBB;
        overflow: hidden;
        white-space: wrap; 
        text-overflow: ellipsis;
    }
    
    .rdtc_item_right a:link {
        color: #679aff ;
        text-decoration: none;
        color: orange;
    }
      
    /* visited link */
    .rdtc_item_right a:visited {
      color: #679aff;
      text-decoration: none;
      color: orange;
    }
      
    /* mouse over link */
    .rdtc_item_right a:hover {
      color: orange;
    }
      
    /* selected link */
    .rdtc_item_right a:active {
      color: orange;
    }
    
    
    
    .match_button {
        border: none; 
        padding-left: 10px;
        padding-right: 10px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 14px;
        margin: 4px 2px;
        border-radius: 12px
    }
    
    .match_button_green {
        color: black;
        border: 1px solid #309090;
        background-color: #40E0D0;
    }
    
    .match_button_yellow {
        border: 1px solid #a98908;
        background-color:  #f4d03fe8;;
        color: black;
    }
    
    .match_button_blue {
        border: 1px solid #3465BD;
        background-color:  rgba(100, 148, 237, 0.69);
        color: #ffffffe0;
    }
    
    .match_button_red {
        border: 1px solid #db6558;
        background-color:   #f1948abb;
        color: black;
    }
    
    .match_button_gene {
        border: 1px solid #77A8B0;
        background-color:  #a7e8f0 ;
        padding-left: 10px;
        padding-right: 10px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 14px;
        margin: 4px 2px;
        border-radius: 2px
    }

    .dark-theme .match_button_gene {
        border: 1px solid #77A8B0;
        background-color:  rgba(3, 133, 17, 0.44);
        padding-left: 10px;
        padding-right: 10px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 14px;
        margin: 4px 2px;
        border-radius: 2px
    }
    
    
    .match_disorder_name {
        font-size: 17px;
        // font-weight: bold;
    }
     
    
    .additional_settings .accordion-button {
        padding-top:2px;
        padding-bottom:2px;
        color:black;   
        background-color: white; 
        border: 0px solid red;
    } 

    
    
    .additional_settings .accordion-button.collapsed {
        padding-top:2px;
        padding-bottom:2px;
        // border-left: none;
        // border-width: 1px 0 0 0;  
        background-color: white;
        border-radius: 0;
    }
    
    .additional_settings .accordion-button:focus {
        box-shadow: none;
        // border-color: rgba(0,0,0,.125);
        background-color: white;
        border-radius: 0;
    }
    
    .accordon {
        --cui-accordion-bg: green;
    }

    .card_subheader {
        color:orange;
        // font-weight: bold;
        font-size: 130%;
        padding-bottom: 0;
        scroll-margin-top:7rem;
    }
    
    .card_property {
        font-style: italic;
        font-weight: bold;
        padding-right: 0.75rem;
    }


    .fasta_table_cell {
        font-family: 'Courier New', Courier, monospace;
    }

    .table > :not(caption) > * > * {
        padding: 0.5rem 0.5rem;
        //   background-color: var(--cui-table-bg);
        border-bottom-width: 1px;
        //   box-shadow: inset 0 0 0 9999px var(--cui-table-accent-bg);
    }


    .project-link {
        font-size: 90%;
        color: gray;
    }

    .table-vector-results tr > td:hover {
        overflow: visible;
        white-space: unset;
    }


// kolor dla wybranej strony w paginatorze
ul.pagination .page-item span.page-link {
    background-color: rgb(60, 179, 60);
    color: rgb(230,230,230);
}



.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: green !important;
}

 

.dark-theme .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: rgb(26, 130, 26) !important;
}

.ant-steps-item-finish  {
    // background: #FFFFFF !important;
    color: magenta
}
.ant-steps-item-finish .ant-steps-item-icon {
    // background: #FFFFFF !important;
    color: magenta
}

.ant-steps-item-finish .ant-steps-item-icon .ant-steps-icon{
    // background: #FFFFFF !important;
    color: rgb(0, 179, 27)  !important;
}

.ant-steps-item-finish .ant-steps-container:hover{
    // background: #FFFFFF !important;
    color: black !important;
}

// step inside element 
.ant-steps-item-finish .ant-steps-item-icon .ant-steps-icon:hover{
    color: yellow !important;
}

.ant-steps-item-finish .ant-steps-item-icon .ant-steps-icon:hover{
    color: black !important;
}

.dark-theme .ant-steps-item-finish .ant-steps-item-icon .ant-steps-icon:hover{
    color: yellow !important;
}


// kolor podpisu (tekst) na hover
.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title:hover {
    color:black !important
}

.dark-theme .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title:hover {
    color:yellow !important
}

// kolor obwódki kółka kroku
.ant-steps-item-finish .ant-steps-item-icon {
    border-color: rgb(26, 130, 26)!important;
}

// kolor obwódki kółka kroku 
.ant-steps-item-finish .ant-steps-item-icon:hover {
    border-color: black !important;
}

// kolor obwódki kółka kroku
.dark-theme .ant-steps-item-finish .ant-steps-item-icon:hover {
    border-color: yellow !important;
}

// kolor obwódki kółka kroku
.ant-steps-item-finish:hover {
    color:magenta !important;
    border-color: magenta !important;
}

// brak zawijania w nazwach krokow
.ant-steps-item-title {
    white-space: nowrap;
}

.fasta_data {
    font-family: 'Courier New', Courier, monospace;    
}

.line-break-anywhere {
    line-break: anywhere;
}


// wlasny alert (tam gdzie toastify bylo zaslaniane)
.custom-alert-shown {
    opacity: 1;
    transition: all 200ms linear;
}
  
.custom-alert-hidden {
    opacity: 0;
    transition: all 700ms linear 2s;
}
 


.remove-btn {
    margin-right: 0.5rem;
  }
  
  .item-enter {
    opacity: 0;
  }
  .item-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
  }
  .item-exit {
    opacity: 1;
  }
  .item-exit-active {
    opacity: 0;
    transition: opacity 500ms ease-in;
  }
  

  .company_name_righttop {
    color: lightgrey;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 3px;
  }